<template>
  <div class="container">
    <div class="div1">董事长介绍</div>
    <div class="div2">Chairman introduction</div>

    <div class="qqq">
        <img class="imgq" :src="data.pic" />
      <div class="one">{{data.title}}</div>
      <p class="two" v-html="data.content">{{data.content}}</p>
    </div>
  </div>
</template>


<script>
  import {getAboutList} from "../../api/zoujindaweijia"
  export default {
    name: "B",
    data(){
      return{
        data:[]
      }
    },
    mounted() {
      getAboutList().then(res=>{
        // console.log(res.data);
        let data = res.data[1];
        // console.log(data);
        this.data = data;
      })

    }
  }
</script>


<style scoped>
  .container{
    /*height: 52.8646vw;*/
  }
  .div1{
 text-align: center;
    height: 2.0833vw;
    font-size: 2.2917vw;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    color: #d7000f;
    margin-top: 4.1146vw;
    letter-spacing:0.15vw;
  }
  .div2{
    height: 1.0417vw;
    font-size: 1.3542vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #646464;
    margin-top: 1.0417vw;
    text-align: center;
  }
  .qqq{
    margin-top: 6vw;
    margin-bottom: 12vw;
  }
  .div3{
    /*margin-left:54.8438vw ;*/
  }
  .imgq{
    margin-left:54.8438vw !important;
    width: 37.2917vw!important;
    height: 25vw!important;
    margin-top: 4.6vw!important;

  }

  /deep/img{
    width: 43vw;
  }

  .one{
    margin-left: 7.8125vw;
    margin-top: -29.38vw;
    width: 20.1667vw;
    height: 3.0899vh;
    font-size: 1.875vw;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    text-align: left;
    color: #000000;
  }
  .two{
    width: 43vw;
    font-size: 1.0417vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #000000;
    margin-left: 7.8125vw;
    margin-top: 2.5625vw;
    margin-bottom: 8vw;
  }

</style>